<template>
  <div class="mobile-list">
    <searchBox placeholder="请输入关键字" :onTextChanged="textChanged" :onCancel="cancel" :onSearchClicked="searchClicked" :msg="enterkey"></searchBox>

    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <div class="mobile-list-card" v-for="(item, index) in items" :key="index" @click="itemClick(item)">
          <div class="mobile-list-row">
            <div class="base-font-color">{{ item.category_name }}</div>
            <div class="light-dark-font-color">
              {{ formatDate(item.add_time, "yyyy-MM-dd hh:mm") }}
            </div>
          </div>
          <div class="mobile-list-row">
            <div class="dark-font-color" style="line-height: 20px">
              {{ item.name }}
            </div>
          </div>
        </div>
        
      </orderScroll>
    </div>
  </div>
</template>

<script>
import { GetNews } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadListAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      filterOption: {
        startTime: null,
        endTime: null,
      },
      enterkey: [],
      keyword: "",
    };
  },
  methods: {
    formatDate: formatDate,
    itemClick: function (item) {
      this.$router.push({
        name: "mNewsDetail",
        query: {
          id: item.id,
        },
      });
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    searchClicked: function (keywords) {
      this.keyword = keywords;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      loadListAction(this, GetNews, {
        sTime: this.filterOption.startTime,
        eTime: this.filterOption.endTime,
        keyword: this.keyword,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          orderBy: "time desc",
        }
      }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.list.length > 0) {
          this.items = this.items.concat(data.list);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    },
  },
  mounted: function () {
    this.firstPage();
  },
};
</script>